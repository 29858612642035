export const backendserverurl='https://pitech-le-saas-bakd-13.pitech.co.ug'

//https://1-be-pifs01-mta-app1.pitech.co.ug
//127.0.0.1:8000
//138.197.8.179
//http://138.197.8.179:10225/
//104.131.54.129:10225


//https://pitech-le-saas-bakd-13.pitech.co.ug/
