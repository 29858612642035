import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch,Tabs,Result} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import SaasClientForm from '../components/SaasClientForm'
import SubdormainCreatorForm from '../components/SubdormainCreaterForm'
import ClientSubdormainList from './ClientSubdormainsListView'
import ClientBackendlinkupForm from '../components/ClientBackendlinkupForm'
import InginxFilesConfigForm from '../components/InginxFilesConfigForm'

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var sacco= ''
var username=''
var token= ''
var ngoid= ''

class ClientsList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    clients: [],
    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    can_view_dashboard:true,
    can_enter_doctor:true,
    can_view_doctors:true,

    can_enter_speciality:true,
    can_view_speciality:true,

    can_enter_patient:true,
    can_view_patients:true,

    can_enter_newcase:true,
    can_edit_case:true,
    can_view_allcases:true,

    can_create_patient_reviews:true,
    can_edit_patient_reviews:true,
    can_view_patient_reviews:true,
    
    can_assign_cases:true,
    can_create_case_review:true,
    can_view_case_reviews:true,
    current_selected_client:'',
    loan_particular_data_loading:false,
    ip_address:'',
    datasubmittedsuccessfully:false,
    message:''

   
  };

  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  
  //////////////////////////////////////
  ///// change password here
  handlePasswordChange=(values)=>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('password1', values.new_password1);
    form_data.append('password2', values.new_password2);
    form_data.append('userid', this.state.selectedid);
  
    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/updatecompanyuserpassword', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({message:res.data.message})
      this.setState({datarequested:false})

      message.info(res.data.message);
    }
    )
    .catch(error => console.log(error)) 
  
  }


  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/clients/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };



  componentDidMount(){

    if(localStorage.getItem("username")){
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/clients/`)
    .then(res => {
        this.setState({
          clients:res.data
        })

        this.setState({datarequested:false})

    })

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [

      {
        title: 'Client Name',
        dataIndex: 'client_name',
        key: 'id',
        ...this.getColumnSearchProps('client_name'),
      },
      {
        title: 'Phone contact',
        dataIndex: 'client_phone',
        key: 'id',
        ...this.getColumnSearchProps('client_phone'),
      },
      {
        title: 'Client email',
        dataIndex: 'client_email',
        key: 'id',
        ...this.getColumnSearchProps('client_email'),
      },
      {
        title: 'Database name',
        dataIndex: 'database_name',
        key: 'id',
        ...this.getColumnSearchProps('database_name'),
      },
      {
        title: 'Database username',
        dataIndex: 'database_username',
        key: 'id',
        ...this.getColumnSearchProps('database_username'),
      },
      {
        title: 'Database password',
        dataIndex: 'database_password',
        key: 'id',
        ...this.getColumnSearchProps('database_password'),
      },
      {
        title: 'Unique identifier',
        dataIndex: 'unique_identifier',
        key: 'id',
        ...this.getColumnSearchProps('unique_identifier'),
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
    
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>

        </p>,
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>       
        </div>
      )

    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL CLIENTS" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.clients} 
             bordered
             
             expandable={{
              expandedRowRender: (record) =>{
                if (Number(record.id)===Number(this.state.current_selected_client)){
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                     >
    
                      {
                        this.state.loan_particular_data_loading===true?
                        <div style={{display:'flex',margin:20}}>
                        <Spin size="large">
                          <div className="content" />
                        </Spin>         
                        </div>
                        :
    
                        <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Subdormains" key="01" >
                          <ClientSubdormainList client_id={record.id} />
                          <br></br>
                          <SubdormainCreatorForm client_id={record.id} />
                        </TabPane>

                        <TabPane tab="More Actions" key="02" >
                          <div style={{display:'flex',flexDirection:'row'}}>
                          <div style={{margin:5,border: '1px solid black',alignItems:'center',borderRadius: '10px',padding:6}}>
                          <ClientBackendlinkupForm client_id={record.id} />
                          </div>

                          <div style={{margin:5,border: '1px solid black',alignItems:'center',borderRadius: '10px',padding:6}}>
                          <InginxFilesConfigForm client_id={record.id} />
                          </div>

                          </div>
                        </TabPane>
                         </Tabs>
                      }
                    </div>
                    );
                }else{
                  return null;
                }
              } ,
              rowExpandable: (record) => record.id !== this.state.current_selected_client.id,
              onExpand:(condition,record)=>{
                this.setState({loan_particular_data_loading:true})
                this.setState({current_selected_client:record.id})
                this.setState({loan_particular_data_loading:false})
              },
            }}
             
             />
            </Panel>
            </Collapse>

            <br></br>
            <SaasClientForm btnText="Create" />
            <br></br>

            <Modal              
              visible={this.state.showpasswordupdatemodal}
              title="Password change Form"
              width={600}
              onCancel={this.closepasswordupdatemodal}

              footer={[
                <Button key="back" onClick={this.closepasswordupdatemodal}>
                  Cancel
                </Button>
                ]}
                >
            <Form
            onFinish={this.handlePasswordChange}        
            name="passwordreset"
            className="login-form">
      
            <Form.Item
                name="new_password1"
                label="New Password one"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password 
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password one"
                />


              </Form.Item>

        
              <Form.Item
                name="new_password2"
                label="Confirm New Password"
                dependencies={['new_password1']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('new_password1') === value) {
                        return Promise.resolve();
                      }
        
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                  }),
                ]}
              >
                <Input.Password 
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Confirm" />

              </Form.Item>

            <Form.Item >
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Confirm Change
                </Button>
            </Form.Item>
          </Form>
                  
          </Modal>
        </div>
    )
    }
   
  }
}

export default ClientsList; 
