import React from 'react';
import { Route } from 'react-router-dom';
import QuickMenu from './views/MainBranch/containers/QuickMenu'
import DashBoard from './views/MainBranch/containers/DashBoard'
import AccountList from './views/MainBranch/containers/AccountsListView'
import AccountDetail from './views/MainBranch/containers/AccountDetailView'
import ClientsList from './views/MainBranch/containers/ClientsListView'
import SubdormainList from './views/MainBranch/containers/SubdormainsListView'

const SaccoBaseRouter = () =>(
     <div>
        <Route exact path='/' component={DashBoard} />
        <Route exact path='/quickmenu/' component={QuickMenu} />
        <Route exact path='/useraccounts/' component={AccountList} />
        <Route exact path='/useraccounts/:accountID' component={AccountDetail} />
        <Route exact path='/clients/' component={ClientsList} />
        <Route exact path='/allsubdormains/' component={SubdormainList} />

    </div>
);

export default SaccoBaseRouter;


