import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch,Tabs,Result} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import SaasClientForm from '../components/SaasClientForm'
import CertficateGenerationForm from '../components/CertficateGenerationForm'

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var token= ''

class ClientSubdormainList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    client_subdormains: [],
    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    can_view_dashboard:true,
    can_enter_doctor:true,
    can_view_doctors:true,

    can_enter_speciality:true,
    can_view_speciality:true,

    can_enter_patient:true,
    can_view_patients:true,

    can_enter_newcase:true,
    can_edit_case:true,
    can_view_allcases:true,

    can_create_patient_reviews:true,
    can_edit_patient_reviews:true,
    can_view_patient_reviews:true,
    
    can_assign_cases:true,
    can_create_case_review:true,
    can_view_case_reviews:true,
    loan_particular_data_loading:false,
    ip_address:'',
    datasubmittedsuccessfully:false,
    message:'',
    current_selected_subdormain:''

   
  };

  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  

  componentDidMount(){
    var client_id=this.props.client_id
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/client_subdormains/?client=${client_id}`)
    .then(res => {
        this.setState({
          client_subdormains:res.data
        })

        this.setState({datarequested:false})

    })

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [

      {
        title: 'Subdormain',
        dataIndex: 'subdormain',
        key: 'id',
        ...this.getColumnSearchProps('subdormain'),
      },
      {
        title: 'IP address',
        dataIndex: 'ip_address',
        key: 'id',
        ...this.getColumnSearchProps('ip_address'),
      },
      {
        title: 'Certificate generated?',
        dataIndex: 'certificate_generated',
        key: 'id',
        render: text =>
        <p> {text===true?"Yes":"No"}</p>
      },
     
    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>       
        </div>
      )

    }else{

      return(
        <div>
          <Table 
            scroll={{ x: 1000 }}
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.client_subdormains} 
            bordered

            expandable={{
              expandedRowRender: (record) =>{
                if (Number(record.id)===Number(this.state.current_selected_subdormain)){
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                     >
    
                      {
                        this.state.loan_particular_data_loading===true?
                        <div style={{display:'flex',margin:20}}>
                        <Spin size="large">
                          <div className="content" />
                        </Spin>         
                        </div>
                        :
    
                        <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Create /update certificate" key="01" >
                          <CertficateGenerationForm  subdormain_id={record.id} subdom_obj={record}/>

                        </TabPane>
                         </Tabs>
                      }
                    </div>
                    );
                }else{
                  return null;
                }
              } ,
              rowExpandable: (record) => record.id !== this.state.current_selected_subdormain.id,
              onExpand:(condition,record)=>{
                this.setState({loan_particular_data_loading:true})
                this.setState({current_selected_subdormain:record.id})
                this.setState({loan_particular_data_loading:false})
              },
            }}

            />
        </div>
    )
    }
   
  }
}

export default ClientSubdormainList; 
