import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined,DollarCircleFilled, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled,CloudSyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../../serverconn'
import * as serversocketconfig from '../../serversocketconfig'

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import * as primarycolor from '../../primarycolor'

import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import BloodtypeSharpIcon from '@mui/icons-material/BloodtypeSharp';
import LocalHospitalSharpIcon from '@mui/icons-material/LocalHospitalSharp';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import OpacityIcon from '@mui/icons-material/Opacity';
var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var sacco= ''
var token= ''

class QuickMenu extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    totalmemberaccounts:0,
    totalfemaleaccounts:0,
    totalmaleaccounts:0,
    totalloansthismonth:0,
    totalmembersavings:0,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    members:[],
    loansformvisible:false,
    monthlyloanissuances:0,
    monthlyloanapplications:0,
    monthlyloanassessments:0,
    monthlyloanapprovals:0,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    reservetranfermodalvisible:false,


    total_of_agents:0,

  };


  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })


   
   //get the user profile here
   axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
   .then(res => {
       this.setState({
         userprofile:res.data
       })
       this.setState({datarequested:false})
   })

  }



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ?(
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


render() {

if(this.state.dashboardlocked===true){
  return(
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
    <h3 style={{fontWeight:'bolder',color:'red'}}>NOTE: Your dashboard is currently locked. Contact system adminstrators.Your Licence may be expired or some thing else. Thanks</h3>
    </div>
  )
}else{

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

        return(
          <div className="animated fadeIn">  
          <h3 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>{this.state.companyprofile.system_name} QUICK MENU</h3>
    
          <Row>

          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <NavLink to='/clients/'> 
          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
              
                <UserOutlined style={{color:primarycolor.primarycolor,fontSize: 50}} />

                <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.total_of_agents}</h6>
                <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of Clients </h6>

              </div>
            </Card>
          </NavLink>
          </Col>


          </Row>
          </div>
        )
      

    }
  
  }

  }
}

export default QuickMenu; 
