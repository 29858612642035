import React from 'react';
import { Form, Input, Button,Spin,Select,Card,Result,message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");
const { Option } = Select;

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''


class CertficateGenerationForm extends React.Component {

  state = {
    client_name:'' ,  
    client_phone:'',
    database_name:'',
    datarequested:false,

    client_email:'',
    database_username:'',
    database_password:'',
    address:'' ,  
    datasubmittedsuccessfully:false,
    ip_address:'',
    message:'',
    subdormain_name:''
   
  }

  componentDidMount(){
    var subdormain_id=this.props.subdormain_id
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({subdormain_name:this.props.subdom_obj.subdormain})

  }


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin> 
        </div>
      )

    }else{

    
        return (
          <div>
            {this.state.datasubmittedsuccessfully===true?
                  <Result
                  status="success"
                  title="Successful"
                  subTitle={this.state.message}
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
              :
              <div>
                 <h4>Sub dormain certificate generation</h4>

                 <FormItem label="sub Name"
                >
                  <Input name="subname"  placeholder="sub name." value={this.state.subdormain_name} onChange={(val)=>{this.setState({subdormain_name:val.target.value})}} />
                </FormItem>


                  <Button type='primary' onClick={()=>{
                    var subdormain_id=this.props.subdormain_id

                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('subdormain_id', subdormain_id);
                    form_data.append('subdormain_name', this.state.subdormain_name);

                    axios.post(serverconfig.backendserverurl+'/customqueries/generate_certificate', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                    })
                    .then(res => {
                      this.setState({message:res.data.message})
                      message.info(res.data.message)
                      this.setState({datarequested:false})
                      this.setState({datasubmittedsuccessfully:true})

                    })
                    .catch(error => console.log(error))

                  }}>Generate certificate</Button>

              </div>
              }
  
          </div>
        );
    }
    
  }

}


export default CertficateGenerationForm;



