import React from 'react';
import {Form,Input,Button,Checkbox,message,Spin,Switch,Select} from 'antd';
import axios from 'axios';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import * as serverconfig from '../../serverconn'
var CryptoJS = require("crypto-js");

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var token= ''
var sacco= ''

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

class  UserCreationForm extends React.Component {

  state = {
    isactive:false,
    ismanager:false,
    profile_pic:null,  
    datarequested:false,
    saccopackageobj:{},
    doctors:[],

    username:'',
    email:'',
    password1:'',
    password2:''

  }


  formRef = React.createRef();

  componentDidMount() {

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/doctors/`)
    .then(res => {
        this.setState({
          doctors:res.data
        })
        this.setState({datarequested:false})

    })
  }



  handleprofile_picChange= (e) => this.setState({ profile_pic: e.target.files[0]});


    // on form submit heree
    onFinish = values => {
      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('username', this.state.username);
      form_data.append('email', this.state.email,);
      form_data.append('password1', this.state.password1);
      form_data.append('password2', this.state.password2);
      form_data.append('is_active', this.state.isactive);
      form_data.append('branch_manager', this.state.ismanager);
      
      this.state.profile_pic==null?
      console.log("No profile file")
      :
      form_data.append('profile_pic', this.state.profile_pic, this.state.profile_pic?this.state.profile_pic.name:"");

      //Register the account first
      axios.post(serverconfig.backendserverurl+'/customqueries/createsystem_user',form_data)
      .then(res =>{
        message.success(res.data.message)

        this.setState({datarequested:false})
        window.location.reload(false)

      })
    .catch(error => console.log(error))

    };

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>  
        </div>
      )

    }else{

        return (

          <div>
            <h3>Create new user account here</h3>
          <Form
            name="register"
            onFinish={this.onFinish}
          >

          <Form.Item
              label="User Name"
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username"  value={this.state.username}  onChange={(val)=>{this.setState({username:val.target.value})}} />
            </Form.Item>
  
            <Form.Item
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" value={this.state.email} onChange={(val)=>{this.setState({email:val.target.value})}}  />
  
            </Form.Item>
      
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              value={this.state.password1}
              onChange={(val)=>{this.setState({password1:val.target.value})}}
              />
  
  
            </Form.Item>
  
      
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
      
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password" 
              value={this.state.password2}
              onChange={(val)=>{this.setState({password2:val.target.value})}}
              />
  
            </Form.Item>
  
            <Form.Item
              label="Is Active"

            >
                <Switch
                    checked={this.state.isactive}
                    onChange={val=>{this.setState({isactive:val})}}
                    checkedChildren="True"
                    unCheckedChildren="False"
                    />
              </Form.Item>
  
              <Form.Item
              label="Is Manager"

            >
                <Switch
                    checked={this.state.ismanager}
                    onChange={val=>{this.setState({ismanager:val})}}
                    checkedChildren="True"
                    unCheckedChildren="False"
                    />            
             </Form.Item>
  
            <Form.Item label="Profile Image"
              name="profile_pic">
                <Input  type="file" accept="image/png, image/jpeg" placeholder="Profile Image" onChange={this.handleprofile_picChange}/>
            </Form.Item>  
  
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create Account
              </Button>
            </Form.Item>
            
          </Form>

          </div>
        );

    }

  }

}


export default UserCreationForm;


