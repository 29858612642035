import React from 'react';
import { Form, Input, Button,Spin,Select,Card,Result,message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");
const { Option } = Select;

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''


class InginxFilesConfigForm extends React.Component {

  state = {
    client_name:'' ,  
    client_phone:'',
    database_name:'',
    datarequested:false,

    client_email:'',
    database_username:'',
    database_password:'',
    address:'' ,  
    datasubmittedsuccessfully:false,
    ip_address:'',
    message:'',
   
  }

  componentDidMount(){
    var client_id=this.props.client_id
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


  }


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin> 
        </div>
      )

    }else{

        return (
          <div>
            {this.state.datasubmittedsuccessfully===true?
                  <Result
                  status="success"
                  title="Successful"
                  subTitle={this.state.message}
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
              :
              <div>
                 <h4>Inginx files configuration</h4>

                  <Button type='primary' onClick={()=>{
                    var client_id=this.props.client_id

                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('client_id', client_id);

                    axios.post(serverconfig.backendserverurl+'/customqueries/client_nginx_files_config', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                    })
                    .then(res => {
                      this.setState({message:res.data.message})
                      message.info(res.data.message)
                      this.setState({datarequested:false})
                      this.setState({datasubmittedsuccessfully:true})

                    })
                    .catch(error => console.log(error))

                  }}>Configure </Button>

              </div>
              }
  
          </div>
        );
    }
    
  }

}


export default InginxFilesConfigForm;



